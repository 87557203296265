<template>
  <div class="container-fluid">
    <div class="page-title">
      <i class="fa-regular fa-warehouse"></i>
      <h1>{{ $t("COMMON.WAREHOUSES") }}</h1>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("WAREHOUSES.WAREHOUSES_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openWarehouseCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_WAREHOUSES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("WAREHOUSES.ADD_WAREHOUSE")
              }}</span>
            </base-button>
            <!--<notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_WAREHOUSES)"
              :objectType="'warehouses'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />-->
          </div>
        </div>
      </div>

      <warehouse-list-table
        @onViewWarehouse="openWarehouseViewModal"
        @onEditWarehouse="openWarehouseEditModal"
        @onDeleteWarehouse="deleteWarehouse"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewWarehouseModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewWarehouseModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'WAREHOUSE'"
          @onCloseModal="closeWarehouseModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("WAREHOUSES.VIEW_WAREHOUSE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openWarehouse"
                :objectType="'warehouses'"
                :objectId="openWarehouse.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openWarehouseEditModal(openWarehouse)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="edit" @click="deleteWarehouse(openWarehouse)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeWarehouseModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-warehouse-page
              v-if="openWarehouse"
              :warehouseId="openWarehouse.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditWarehouseModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditWarehouseModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'WAREHOUSE'"
          @onCloseModal="closeWarehouseModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("WAREHOUSES.EDIT_WAREHOUSE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button
                class="close"
                @click="openWarehouseViewModal(openWarehouse)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-warehouse-page
              v-if="openWarehouse"
              :warehouseId="openWarehouse.id"
              @onViewWarehouse="openWarehouseViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddWarehouseModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddWarehouseModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'WAREHOUSE'"
          @onCloseModal="closeWarehouseModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("WAREHOUSES.ADD_WAREHOUSE") }}
            </h1>
            <button class="close" @click="closeWarehouseModal(true)">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-warehouse-page @onViewWarehouse="openWarehouseViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import WarehouseListTable from "./partials/WarehouseListTable.vue";
import EditWarehousePage from "./components/EditWarehouseComponent.vue";
import AddWarehousePage from "./components/AddWarehouseComponent.vue";
import ViewWarehousePage from "./components/ViewWarehouseComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    WarehouseListTable,
    NotificationSubscription,
    EditWarehousePage,
    AddWarehousePage,
    ViewWarehousePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewWarehouseModalOpened = false;
    let isEditWarehouseModalOpened = false;
    let isAddWarehouseModalOpened = false;
    let openWarehouse = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewWarehouseModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditWarehouseModalOpened = true;
      }
      openWarehouse = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddWarehouseModalOpened = true;
    }
    return {
      isViewWarehouseModalOpened: isViewWarehouseModalOpened,
      isEditWarehouseModalOpened: isEditWarehouseModalOpened,
      isAddWarehouseModalOpened: isAddWarehouseModalOpened,
      openWarehouse: openWarehouse,
      renderKey: 1,
    };
  },

  methods: {
    openWarehouseCreateModal() {
      this.closeWarehouseModal();
      this.isAddWarehouseModalOpened = true;

      this.$router.replace({
        name: "List Warehouses",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },
    openWarehouseViewModal(warehouse, reRender = false) {
      this.closeWarehouseModal();
      this.openWarehouse = warehouse;
      this.isViewWarehouseModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Warehouses",
        query: { id: this.openWarehouse.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    openWarehouseEditModal(warehouse) {
      this.closeWarehouseModal();
      this.openWarehouse = warehouse;
      this.isEditWarehouseModalOpened = true;

      this.$router.replace({
        name: "List Warehouses",
        query: { id: this.openWarehouse.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    closeWarehouseModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddWarehouseModalOpened = false;
      this.isViewWarehouseModalOpened = false;
      this.isEditWarehouseModalOpened = false;
      this.openWarehouse = null;

      if (
        this.$route.name !== "List Warehouses" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Warehouses",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async deleteWarehouse(warehouse) {
      const confirmation = await swal.fire({
        title: this.$t("WAREHOUSES.DELETE_THIS_WAREHOUSE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("warehouses/destroy", warehouse.id);
          this.renderKey++;
          this.closeWarehouseModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("WAREHOUSES.WAREHOUSE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
